<template>
    <div v-if="getSelectOrg.selectOrg.orgName">
        <!-- <a-menu mode="inline" :openKeys="openKeys" style="width: 200px" @openChange="onOpenChange" @click="clickMenu">
            <template v-for="(item,index) in menu">
                <a-sub-menu v-if="item.list" :key="index">
                    <span slot="title"><a-icon :type="item.ico" /><span>{{ item.name }}</span></span>

                    <a-menu-item v-for="(i_item) in item.list" :key="i_item.index">
                       <a-icon :type="i_item.ico" />{{ i_item.name }}
                    </a-menu-item>
                    
                </a-sub-menu>


                <a-menu-item v-else :key="item.index">
                    <a-icon :type="item.ico" />{{ item.name }}
                </a-menu-item>
            </template>
        
        </a-menu> -->

        <ul>
            <router-link tag="li" v-for="(item,index) in menu" :key="index" :to="item.link">
                <a-icon :type="item.ico" />
                <span>{{ item.name }}</span>
            </router-link>
        </ul>
    </div>
</template>
<script>

export default {

    data(){
        return {
            rootSubmenuKeys: [0,1,2,3,4,5,6,7,8],
            openKeys: [0],
            selectedKeys:[0],
            menu: [{
                    name: '工作台',
                    index: 0,
                    ico: 'home',
                    link: '/work'
                },{
                    name: '合同模版', 
                    link: '/template/manage',
                    index: 1,
                    ico: 'file-protect'
                },{
                    name: '经办合同',
                    link: '/contract',
                    index: 2,
                    ico: 'cluster'
                },{
                    name: '归档合同',
                    link: '/archiveContract',
                    index: 3,
                    ico: 'database'
                },{
                    name: '印章管理',
                    index: 4,
                    ico: 'safety',
                    link: '/my/seal'
                },{
                    name: '员工管理',
                    index: 5,
                    ico: 'team',
                    link: '/my/staff'
                },{
                    name: '相对方管理',
                    index: 6,
                    ico: 'code-sandbox',
                    link: '/opposite'
                },{
                    name: '权益中心',
                    index: 7,
                    ico: 'gold',
                    link: '/legalCenter'
                },{
                    name: '帮助',
                    index: 8,
                    ico: 'info-circle',
                    link: '/my/help'
                }]
        }
    },
    computed:{
        getSelectOrg(){
            return this.$store.getters.getOrg
        }
    },
    mounted(){
        // let { hash } = location
        // if(hash.indexOf('/my') >=0 ){
        //     this.openKeys = [1]
        // }else{
        //     this.menu.forEach(item=>{
        //         if(hash.indexOf(item.link) >= 0){
        //             this.selectedKeys = [item.index]
        //         }
        //     })
        // }
    },
    methods:{
        // chanmeMenu(item){
        //     this.selectMenu = item.index
        //     this.$router.push({
        //         path: item.link
        //     })
        // },

        onOpenChange(openKeys) {
            console.log(openKeys)
            // this.openKeys = openKeys;
            const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
            if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
                this.openKeys = openKeys;
            } else {
                this.openKeys = latestOpenKey ? [latestOpenKey] : [];
            }
        },
        clickMenu({ item, key, keyPath }){
            if(this.$route.path != this.menu[key].link){
                this.$router.push({
                    path: this.menu[key].link
                })
            }
            // if(keyPath.length == 2){
            //     let list = this.menu[keyPath[1]].list
            //     list.forEach(info=>{
            //         if(info.index == keyPath[0]){
            //             if(this.$route.path != info.link){
            //                 this.$router.push({
            //                     path: info.link
            //                 })
            //             }
                        
            //         }
            //     })
                
            // }else{
            //     console.log(this.$route.path , this.menu[key].link)
            //     if(this.$route.path != this.menu[key].link){
            //         this.$router.push({
            //             path: this.menu[key].link
            //         })
            //     }
            // }
            console.log(item, key, keyPath)
        }
    }
}
</script>
<style lang="less" scoped>
@import '../assets/less/common.less';
ul {
    padding-top: 20px;
    cursor: pointer;
    li {
        padding: 15px 20px;
        display: flex;
        align-items: center;
        i {
            font-size: 13px;
        }
        span {
            margin-left: 10px;
        }
    }
    .router-link-active {
        background: @theme-bg;
        color: @theme;
    }
}
@media screen and (max-width: 1024px) {
    ul {
        li {
            flex-direction: column;
            i {
                font-size: 18px;
                padding-bottom: 5px;
            }
            span {
                margin-left: 0px;
            }
        }
    }
}
</style>