import { post,get } from '@/http/http'

// 查询用户信息
export const queryUserInfo = (option) => {
   return post('/esignServer/userController/queryUserInfo', option)
}

export const getUserInfo = (option) => {
    return get('/esignServer/maxKeyLoginController/getUserInfo', option)
 }
 



// 查询用户信息及关联机构
export const queryUserAndOrg = (option) => {
    return post('/esignServer/userController/queryUserAndOrg', option)
}

// 更新个人用户信息
export const updateUserInfo = (option) => {
    return post('/esignServer/userController/updateUserInfo', option)
}


// 第三方查询/初始化用户
export const queryUserInfoForApp = (option) => {
    return post('/esignServer/userController/queryUserInfoForApp', option)
}

// 个人用户实名认证
export const verificationUser = (option) => {
    return post('/esignServer/userController/verificationUser', option)
}

// 删除机构
export const delOrg = (option) => {
    return post('/esignServer/orgController//delOrg', option)
}


// 创建机构
export const addOrg = (option) => {
    return post('/esignServer/orgController/addOrg', option)
}


// 编辑机构信息
export const updateOrg = (option) => {
    return post('/esignServer/orgController/updateOrg', option)
}

// 机构认证
export const verificationOrg = (option) => {
    return post('/esignServer/orgController/verificationOrg', option)
}

// 机构法人变更
export const changeLegal = (option) => {
    return post('/esignServer/orgController/changeLegal', option)
}


// 查询用户关联机构
export const getUserOrg = (option) => {
    return post('/esignServer/orgController/getUserOrg', option)
}


// 查询企业员工
export const queryOrgUser = (option) => {
    return post('/esignServer/orgController/queryOrgUser', option)
}


// 邀请成员
export const inviedUser = (option) => {
    return post('/esignServer/inviteController/inviteUser', option)
}

// 查询邀请成员列表
export const queryInviteUserByOrgId = (option) => {
    return post('/esignServer/inviteController/queryInviteUserByOrgId', option)
}

// 取消邀请
export const cancelInvite = (option) => {
    return post('/esignServer/inviteController/cancelInvite', option)
}

// 机构冻结成员
export const stopUser = (option) => {
    return post('/esignServer/orgController//stopUser', option)
}

// 机构激活成员
export const activeUser = (option) => {
    return post('/esignServer/orgController/activeUser', option)
}

// 邀请用户同意加入机构
export const agreeInvite = (option) => {
    return post('/esignServer/inviteController/agreeInvite', option)
}


// 机构删除成员
export const delUser = (option) => {
    return post('/esignServer/orgController/delUser', option)
}


// 查询用户权益消费记录
export const queryConsumeRecord = (option) => {
    return post('/esignServer/consumeController/queryConsumeRecord', option)
}

// 查询用户权益订阅记录
export const queryHisSubscribe = (option) => {
    return post('/esignServer/subscribeController/queryHisSubscribe', option)
}

// 查询用户当前权益
export const queryCurBenefit = (option) => {
    return post('/esignServer/subscribeController/queryCurBenefit', option)
}


/** 发送短信验证码 */
export const getRegistSmsCode = (option) => {
    return get('/esignServer/userController/getRegistSmsCode', option)
}
/** 发送短信验证码 */
export const _getRegistSmsCode = (option) => {
    return post('/api/WEBRESTravel', option)
}

/** 用户注册 */
export const userRegist = (option) => {
    return post('/esignServer/userController/userRegist', option)
}


/** 用户登录 */
export const userLogin = (option) => {
    return post('/esignServer/userController/userLogin', option)
}

/** 用户重置密码   */
export const resetPasswd = (option) => {
    return post('/esignServer/userController/resetPasswd', option)
}

export const checkAccountStatus = (option) => {
  return post('/esignServer/wxUserController/checkAccountStatus', option)
}







 



 
