import { post } from '@/http/http'

// // 文件上传
// export const HTuploadFile = (option) => {
//    return post('/esignServer/contractController/uploadFile', option)
// }

// 模版文件上传
export const uploadFile = (option) => {
    return post('/esignServer/contractTemplateController/uploadFile', option)
}

 

// 模版文件预览
export const previewFile = (option) => {
    return post('/esignServer/contractTemplateController/previewFile', option)
}
 

// 查询合同类别
export const queryContractTemplateCategory = (option) => {
    return post('/esignServer/contractTemplateController/queryContractTemplateCategory', option)
}

// 添加合同模板
export const addContractTemplate = (option) => {
    return post('/esignServer/contractTemplateController/addContractTemplate', option)
}

// 修改合同模板
export const updateContractTemplate = (option) => {
    return post('/esignServer/contractTemplateController/updateContractTemplate', option)
}


// 查看模版详情
export const queryContractTemplateDetail = (option) => {
    return post('/esignServer/contractTemplateController/queryContractTemplateDetail', option)
}

// 查询合同详情
export const queryContractById = (option) => {
    return post('/esignServer/contractController/queryContractById', option)
}


// 删除合同模板
export const delContractTemplate = (option) => {
    return post('/esignServer/contractTemplateController/delContractTemplate', option)
}

// 查询私有模板
export const queryPrivateContractTemplate = (option) => {
    return post('/esignServer/contractTemplateController/queryPrivateContractTemplate', option)
}

// 查询公共模板
export const queryPublicContractTemplate = (option) => {
    return post('/esignServer/contractTemplateController/queryPublicContractTemplate', option)
}

// 上线/下线合同模板
export const upDownContractTemplate = (option) => {
    return post('/esignServer/contractTemplateController/upDownContractTemplate', option)
}




// 添加合同
export const addContract = (option) => {
    return post('/esignServer/contractController/addContract', option)
}

// 修改合同
export const editContract = (option) => {
    return post('/esignServer/contractController/editContract', option)
}

// 操作合同状态
export const proContractStatus = (option) => {
    return post('/esignServer/contractController/proContractStatus', option)
}

// 填充合同
export const setContractContent = (option) => {
    return post('/esignServer/contractController/setContractContent', option)
}



// 创建个人印章
export const createUserSeal = (option) => {
    return post('/esignServer/sealController/createUserSeal', option)

}

// 创建企业印章
export const createOrgSeal = (option) => {
    return post('/esignServer/sealController/createOrgSeal', option)
}

// 查询印章
export const querySealByOwner = (option) => {
    return post('/esignServer/sealController/querySealByOwner', option)
}

// 用户签署
export const sign = (option) => {
    return post('/cmUser/signController/sign', option)
}


// 查询我发起的合同
export const querySendContract = (option) => {
    return post('/esignServer/contractController/querySendContract', option)
}

// 查询我接收的合同
export const queryReceiveContract = (option) => {
    return post('/esignServer/contractController/queryReceiveContract', option)
}

// 查询已完成的合同
export const queryFinishContract = (option) => {
    return post('/esignServer/contractController/queryFinishContract', option)
}

// 查询待我签署的合同
export const queryWaitMeContract = (option) => {
    return post('/esignServer/contractController/queryWaitMeContract', option)
}


// 收藏模板
export const favoriteTemplate = (option) => {
    return post('/esignServer/contractTemplateController/favoriteTemplate', option)
}

// 查询收藏模板
export const queryFavoriteTemplate = (option) => {
    return post('/esignServer/contractTemplateController/queryFavoriteTemplate', option)
}

// 删除收藏模板
export const delFavoriteTemplate = (option) => {
    return post('/esignServer/contractTemplateController/delFavoriteTemplate', option)
}


//添加相对方
export const addPartner = (option) => {
    return post('/esignServer/contractPartnerController/addPartner', option)
}

//修改相对方
export const editPartner = (option) => {
    return post('/esignServer/contractPartnerController/editPartner', option)
}

//删除相对方
export const delPartner = (option) => {
    return post('/esignServer/contractPartnerController/delPartner', option)
}

//查询相对方列表
export const queryContractPartner = (option) => {
    return post('/esignServer/contractPartnerController/queryContractPartner', option)
}




