/**
 * axios 参考文档
 * http://www.axios-js.com/zh-cn/docs/
 *
 */
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';

import store from '@/store'


export const request = axios.create({
	baseURL: '',
	timeout: 15000,
	withCredentials: false,
	crossDomain: true,
})

// 添加请求拦截器
request.interceptors.request.use(async function (config) {
	let traceId = uuidv4().replace(/-/g,'').slice(0,18)

	if(config.type == 'from'){
		//
	}else{
		config.data = Object.assign({
			// userId: 100000001,
			// accountId: 'U1111111111111',
			// traceId: traceId
		},config.data)
	}
	

	console.log(config.data)

	config.headers['X-lang'] = 'zhCn'
	config.headers['userId'] = localStorage.getItem('userId') || '' // 100000001
	config.headers['accountId'] =  config?.data?.accountId || localStorage.getItem('orgAccountId')
	config.headers['orgAccountId'] = config?.data?.accountId
	config.headers['traceId'] = traceId  // 18位随机数
	return config
})

// 添加响应拦截器
request.interceptors.response.use(async function (response) {
	return response
})

/**
 *
 * @param {*} url  完整的请求链接，而不是相对路径
 * @param {*} paramet  axios 所有参数，在这个基础上增加了部分参数
 * @param {*} method  请求方式
 * @returns Promise
 */
const sendHttp = (url, paramet, method) => {
	store.dispatch('setLoading', true)
	return new Promise((resolve, reject) => {
		let resData = Object.assign(
			{
				method: method || paramet.method,
				url: url || paramet.url,
			},
			paramet,
		)
		request(resData).then(res => {
			resolve(res)
		}).catch(err => {
			reject(err)
		}).finally(()=>{
			store.dispatch('setLoading', false)
		})
	})
}

export const get = (url, paramet) => {
	return sendHttp(url, Object.assign({},paramet), 'get')
}
export const post = (url, paramet) => {
	const postConfig = {}
	return sendHttp(url, Object.assign(postConfig, paramet), 'post')
}
export default {
	get,
	post,
	request: sendHttp
}
