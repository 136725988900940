<template>
<a-config-provider :locale="locale">
    <div id="app">
      <Header v-if="isShowHeader"></Header>
      <div class="content">
        <Menu class="left-menu" v-if="isShowMenu"></Menu>
        <div class="right">
          <div class="loading" v-if="$store.getters.getLoading">
            <a-spin size="large" />
          </div>
          <router-view></router-view>
        </div>
      </div>
    
    </div>
  </a-config-provider>
</template>

<script>
import Header from "@/components/Header.vue";
import Menu from "@/components/Menu.vue";

import zhCN from 'ant-design-vue/es/locale/zh_CN';
// api
// import { getUserInfo } from "@/api";
export default {
  name: "App",
  data(){
    return {
      locale: zhCN,
      resOk: true,
    }
  },
  computed:{
    isShowMenu(){
      let path = this.$route.path
      if(path == '/home' || path == '/' || path == '/login' || path == '/resetPassword'){
        return false
      }else{
        return true
      }
    },
    isShowHeader(){
      let path = this.$route.path
      if(path == '/login' || path == '/resetPassword'){
        return false
      }else{
        return true
      }
    }
  },
  async mounted() {
    console.log(1111)
    if(localStorage.getItem('accountId') && localStorage.getItem('userId')){
          this.$store.dispatch("setState", {
              userInfo: JSON.parse(localStorage.getItem('YDuserInfo') || '{}') || {},
          });
    }else{
      this.$router.push({
        name: 'login'
      })
    }

        
    // 查询用户信息并保存
    // let search = location.search || ''
    // let code = ''
    // if(search){
    //   let list = search.split('?code=')
    //   code = list[1] || ''
    // }

    // // if(localStorage.removeItem('userId') && ){
    //   if(code){
    //     localStorage.removeItem('userId')
    //     localStorage.removeItem('accountId')
    //   }

    //   await getUserInfo({
    //     params:{
    //       code: code || 'abc'
    //     }
    //   }).then(({ data }) => {
    //       let res = data?.data;
    //       if(res){
    //         localStorage.setItem('userId', res.userId || '')
    //         localStorage.setItem('accountId', res.accountId || '')
    //         localStorage.setItem('YDuserInfo',JSON.stringify(res))
    //         // 存储用户信息
    //         this.$store.dispatch("setState", {
    //           userInfo: res || {},
    //         });
    //         // window.location.href = window.location.origin
    //       }else{
    //         if(localStorage.getItem('accountId')){
    //           this.resOk = true
    //         }else{
    //           if(localStorage.getItem('accountId') && localStorage.getItem('userId')){
    //             //
    //           }else{
    //             // window.location.href = data.msg
    //           }
              
    //         }

    //         this.$store.dispatch("setState", {
    //           userInfo: JSON.parse(localStorage.getItem('YDuserInfo') || '{}') || {},
    //         });
            
    //       }
    //       this.resOk = true
    //   })
      
    // }else{
    //   this.resOk = true
      // queryUserInfo({
      //   data:{
      //     accountId: 'U1111111111111'
      //   }
      // }).then(({ data }) => {
      //   let res = data?.data;

      //   localStorage.setItem('userId', res.userId)
      //   // 存储用户信息
      //   this.$store.dispatch("setState", {
      //     userInfo: res || {},
      //   });
      // });

      
    // }
  },
  components: {
    Header,
    Menu,
  },
};
</script>
<style lang="less" scoped>
.content {
  display: flex;
  height: calc(100vh - 56px);
  .left-menu {
    width: 200px;
    min-width: 200px;
    height: 100%;
    border-right: 1px solid rgb(230 230 230);
    background: #fff;
  }
  .right {
    width: 100%;
    height: 100%;
    position: relative;
    
  }
}
.loading {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background: rgba(255,255,255,0.4);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1024px) {
  .content {
    .left-menu {
      width: 100px;
      min-width: 100px;
    } 
  }
}
</style>
