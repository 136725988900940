import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)


const router = new VueRouter({
    // mode: 'history',
    routes:[{
      path: '/',
      redirect: '/login'
    },{
      path: '/home',
      name: 'home',
      des: '工作台',
      component: () =>import(/* webpackChunkName: "purchasingMain" */ '@/views/home.vue'),
    },{
      path: '/legalCenter',
      name: 'legalCenter',
      des: '权益中心',
      component: () =>import(/* webpackChunkName: "purchasingMain" */ '@/views/legalCenter.vue'),
    },{
      path: '/opposite',
      name: 'opposite',
      des: '相对方管理',
      component: () =>import(/* webpackChunkName: "purchasingMain" */ '@/views/opposite.vue'),
    },{
      path: '/work',
      name: 'work',
      des: '工作台',
      component: () =>import(/* webpackChunkName: "purchasingMain" */ '@/views/work.vue'),
      children:[{
        path: '/work/',
        name: 'workIndex',
        component: () =>import(/* webpackChunkName: "purchasingMain" */ '@/views/work/index.vue'),
      },{
        path: '/work/sign',
        name: 'workSign',
        component: () =>import(/* webpackChunkName: "purchasingMain" */ '@/views/work/sign.vue'),
      }]
    },{
      path: '/my',
      name: 'my',
      des: '我的',
      redirect: '/my/information',
      component: () =>import(/* webpackChunkName: "purchasingMain" */ '@/views/my.vue'),
      children:[{
          path: '/my/information',
          name: 'information',
          component: () =>import(/* webpackChunkName: "purchasingMain" */ '@/views/my/information.vue'),
        },{
          path: '/my/seal',
          name: 'seal',
          component: () =>import(/* webpackChunkName: "purchasingMain" */ '@/views/my/seal.vue'),
        },{
          path: '/my/addSeal',
          name: 'addSeal',
          component: () =>import(/* webpackChunkName: "purchasingMain" */ '@/views/my/addSeal.vue'),
        },{
          path: '/my/legalCenter',
          name: 'legalCenter',
          component: () =>import(/* webpackChunkName: "purchasingMain" */ '@/views/my/legalCenter.vue'),
        },{
          path: '/my/staff',
          name: 'staff',
          component: () =>import(/* webpackChunkName: "purchasingMain" */ '@/views/my/staff.vue'),
        },{
          path: '/my/contract',
          name: 'contract',
          component: () =>import(/* webpackChunkName: "purchasingMain" */ '@/views/my/contract.vue'),
        },{
          path: '/my/help',
          name: 'help',
          component: () =>import(/* webpackChunkName: "purchasingMain" */ '@/views/my/help.vue'),
        },{
          path: '/my/organization',
          name: 'organization',
          component: () =>import(/* webpackChunkName: "purchasingMain" */ '@/views/my/organization.vue'),
        }]
    },{
      path: '/contract',
      name: 'contract',
      des: '经办合同',
      component: () =>import(/* webpackChunkName: "purchasingMain" */ '@/views/contract.vue'),
    },{
      path: '/archiveContract',
      name: 'archiveContract',
      des: '归档合同',
      component: () =>import(/* webpackChunkName: "purchasingMain" */ '@/views/archiveContract.vue'),
    },{
      path: '/template',
      name: 'template',
      des: '合同模版',
      redirect: '/template/manage',
      component: () =>import(/* webpackChunkName: "purchasingMain" */ '@/views/template.vue'),
      children:[{
        path: '/template/type',
        name: 'manage',
        component: () =>import(/* webpackChunkName: "purchasingMain" */ '@/views/template/type.vue'),
      },{
        path: '/template/manage',
        name: 'manage',
        component: () =>import(/* webpackChunkName: "purchasingMain" */ '@/views/template/manage.vue'),
      },{
        path: '/template/square',
        name: 'square',
        component: () =>import(/* webpackChunkName: "purchasingMain" */ '@/views/template/square.vue'),
      },{
        path: '/template/collection',
        name: 'collection',
        component: () =>import(/* webpackChunkName: "purchasingMain" */ '@/views/template/collection.vue'),
      }]
    },{
      path: '/seal',
      name: 'seal',
      component: () =>import(/* webpackChunkName: "purchasingMain" */ '@/views/seal.vue'),
  },{
    path: '/login',
    name: 'login',
    des: '登陆',
    component: () =>import(/* webpackChunkName: "purchasingMain" */ '@/views/login.vue'),
  },{
      path: '/resetPassword',
      name: 'resetPassword',
      component: () =>import(/* webpackChunkName: "purchasingMain" */ '@/views/resetPassword.vue'),
  }],
})







// let routerLength = 0 //vue 路由历史长度
// let showClosed = false //app是否已显示关闭按钮
// let lastHistoryLength = 0 //history 长度缓存
// router.afterEach((to, from)=>{
//   NProgress.done()
//   if(!lastHistoryLength){
//     routerLength += 1
//   } else {
//     if(history.length > lastHistoryLength){
//       routerLength += 1
//     }
//   }
//   lastHistoryLength = history.length
//   //判断历史路由长度并显示app关闭按钮
//   if(!showClosed && routerLength > 1){
//     JSBridge && JSBridge.setClose(true)
//     showClosed = true
//   }
// })



// router.beforeResolve((to, from, next) => {
//   // console.log(to,form)
//   console.log(to.query)
//   to.query.oid = 123
// 	next({
//     query:{
//       a: 123
//     }
//   })
// })


// router.beforeEach((to, from , next) => {
//   // let { oid } = from.query
//   // let path = to.path
//   // to.query
//   to.query.detailid = 'name';
//   console.log(to.query)
 
//   // if(path != '/home' && oid){
  
//   // }
//   if(to.path == from.path){
//     next()
//   }else{
//     next(to)
//   }
  
// });

export default router