<template>
    <header>
        <p @click="goHome" class="logo">
            <img src="@/assets/logo.jpg"/>
            <span v-if="getSelectOrg.selectOrg.orgName"> {{ getSelectOrg.selectOrg.orgName }}</span>
            <!-- <a-button v-else class="selectOrg" type="primary">请选择企业</a-button> -->
        </p>
        <ul>
            <!-- <li>账号：</li>
            <li>
                <a-select style="width: 200px" :defaultValue="0 || '请选择'" placeholder="请选择公司/个人" @change="handleChange">
                    <a-select-option :value="index" v-for="(item,index) in orgInfoList" :key="index">
                        {{ item.orgName }}
                    </a-select-option>
                </a-select>
            </li> -->
            <!-- <router-link tag="li" :to="item.link" v-for="(item,index) in menuList" :key="index" :class="item.class">{{ item.name }}</router-link> -->
            <li class="tx"><img src="@/assets/image/index/tx.png"/></li>
            <li class="logout" @click="logout">退出登录</li>
        </ul>
    </header>
</template>
<script>

// api
import { queryUserAndOrg } from '@/api'
export default {
    data(){
        return {
            menuList:[{
                name: '待办中心',
                index: 1,
                link: '/contract'
            },{
                name: '个人中心',
                index: 2,
                link: '/home'
            }],

            orgInfoList: [],
            selectOrg: [],
        }
    },
    computed:{
        getSelectOrg(){
            return this.$store.getters.getOrg
        }
    },
    mounted(){
        if(localStorage.getItem('accountId')){
            queryUserAndOrg({
                data:{
                    accountId: localStorage.getItem('accountId')
                }
            }).then(({ data })=>{
                let res = data?.data || {}
                this.orgInfoList = res.orgInfoList || []

                this.selectOrg = res.orgInfoList[0] || {}


                let storeData = {
                    orgInfoList: res.orgInfoList || [],
                }
                
                let orgId = localStorage.getItem('orgId')
                if(orgId){
                    this.orgInfoList.forEach(item=>{
                        if(item.orgId == orgId){
                            storeData.selectOrg = item
                            storeData.OrgId = item.orgId
                        }
                    })
                }
                this.$store.dispatch('setState', storeData)
            })
        }else{
            this.$router.push({
                name: 'login'
            })
        }
        
    },
    methods:{
        /** 切换公司 */
        handleChange(index){
            this.$store.dispatch('setState', {
                selectOrg: this.orgInfoList[index] || {},
                OrgId: this.orgInfoList[index]?.orgId || ''
            })
        },
        goHome(){
            localStorage.setItem('orgId', '')
            this.$router.push({
                path: '/home'
            })
        },
        /** 推出登录 */
        logout(){
            localStorage.setItem('userId', '')
            localStorage.setItem('accountId', '')
            localStorage.setItem('YDuserInfo',JSON.stringify({}))
            this.$router.push({
                name: 'login'
            })
        }
    }
}
</script>
<style lang="less" scoped>
@import '../assets/less/common.less';
header{
    height: 56px;
    line-height: 56px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 0px 5px #c4c4c4;
    z-index: 10;
    background: #fff;
    position: relative;
    .logo{
        font-size: 26px;
        font-weight: 600;
        margin-left: 30px;
        // min-width: 300px;
        line-height: 56px;
        display: flex;
        align-items: center;
        overflow: hidden;
        img {
            width: 87px;
            padding: 10px;
        }
        span {
            width: 200px;
            font-weight: 500;
            font-size: 14px;
            margin-left: 5px;
        }
    }
    ul {
        // width: 100%;
        display: flex;
        justify-content: end;
        align-items: center;
        padding-right: 20px;
        cursor: pointer;
        li {
            font-size: 14px;
            color: #000;
            padding: 0px 22px;
            height: 28px;
            line-height: 28px;
            &:hover{
                transition: all 0.2s ease;
               color: @theme; 
            }
        }
        .all-enterprise {
            border-right: 1px solid #e2e2e2;
        }
        .tx {
            img {
                width: 30px;
            }
            
        }
        .logout {
            padding: 0 16px;
        }
    }
   
}
.selectOrg {
    line-height: 28px !important;
    margin-left: 20px;
    span {
        line-height: 30px !important;
        height: 30px !important;
    }
}
</style>